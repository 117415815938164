import { Field, Form } from 'react-final-form';

import MarkdownText from 'components/atoms/markdown';
import { StyledField } from 'components/atoms/form';
import axios from 'axios';
import styled from 'styled-components';
import { useState } from 'react';

const YellowBox = styled.aside`
  background: ${({ theme }) => theme.colors.secondaryGradient};
  box-shadow: ${({ theme }) => theme.colors.shadow};
  color: ${({ theme }) => theme.colors.textInverse};
  border-radius: 5px;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.lg.minWidth}) {
    width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : '66%')};
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: ${({ theme }) => theme.typography.desktopFontSize.subTitle}; ;
`;

const EmailInputWrapper = styled.div`
  background: rgba(196, 196, 196, 0.4);
  border-radius: 5px;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding: 0;
  width: 100%;
`;

const EmailInput = styled.input`
  border: 0;
  flex: 1;
  padding: 0.25rem 1rem;
  background: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.placeholder};

  &:focus-visible {
    outline: none;
  }
  &:focus-within {
    outline: 1px solid rgba(0, 0, 0, 0.25);
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.placeholder};
  }
`;

const SubmitButton = styled.button`
  margin: 0.5rem;
  background: ${({ theme }) => theme.colors.pageBackground};
  border: 0;
  border-radius: 5px;
  width: 55px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const required = (value) => (!!value ? undefined : 'This field is required');

/*
I consent to being contacted in accordance with SatsGoal&apos;s <a href="https://www.iubenda.com/privacy-policy/68396819" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.

*/

export default function EmailSignup({
  title,
  privacyText,
  emailPlaceholder,
  postSignUpText,
  isFullWidth,
}) {
  const [submitted, setSubmitted] = useState(false);
  return (
    <YellowBox $isFullWidth={isFullWidth}>
      <Title>{title}</Title>
      <Form
        onSubmit={async (values) => {
          const resp = await axios.post('/api/email-signup', values);
          if (resp.status === 200) setSubmitted(true);
        }}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              gap: '1rem',
              flexDirection: 'column',
            }}
          >
            <Field name="consentGiven" type="checkbox" validate={required}>
              {({ input, meta }) => {
                return (
                  <StyledField
                    error={meta.touched && meta.error}
                    style={{ width: '100%' }}
                  >
                    <CheckboxLabel>
                      <input
                        style={{ accentColor: 'white', color: 'black' }}
                        {...input}
                      />
                      <MarkdownText onPrimaryBg>{privacyText}</MarkdownText>
                    </CheckboxLabel>
                  </StyledField>
                );
              }}
            </Field>
            <Field name="email" type="email" validate={required}>
              {({ input, meta }) => (
                <StyledField
                  error={meta.touched && meta.error}
                  style={{ width: '100%' }}
                >
                  {submitted ? (
                    <MarkdownText onPrimaryBg>{postSignUpText}</MarkdownText>
                  ) : (
                    <EmailInputWrapper>
                      <EmailInput {...input} placeholder={emailPlaceholder} />
                      <SubmitButton type="submit" aria-label="Submit">
                        <svg
                          style={{ width: '100%', height: '100%' }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M502.6 278.6l-128 128c-12.51 12.51-32.76 12.49-45.25 0c-12.5-12.5-12.5-32.75 0-45.25L402.8 288H32C14.31 288 0 273.7 0 255.1S14.31 224 32 224h370.8l-73.38-73.38c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l128 128C515.1 245.9 515.1 266.1 502.6 278.6z"
                            fill="currentColor"
                          />
                        </svg>
                      </SubmitButton>
                    </EmailInputWrapper>
                  )}
                </StyledField>
              )}
            </Field>
          </form>
        )}
      />
    </YellowBox>
  );
}
